@import "~normalize.css";
html, body {
  height: 100%;
}

body {
  font-family: var(--typo-base-family);
  font-size: var(--typo-base-size);
  line-height: var(--typo-base-height);
  font-weight: normal;
  background-color: var(--color-background);
  color: var(--color-on-background);
  overflow-y: scroll;
}
body > picture > img {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: -10;
}

main > *,
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message-group {
  font-size: var(--typo-small-size);
  line-height: var(--typo-small-height);
  width: 100%;
  margin: 10px;
  overflow: hidden;
}
.message-group div {
  padding: 10px;
}
.message-group div.error {
  background-color: var(--color-error);
  color: var(--color-on-error);
}
.message-group div.success {
  background-color: var(--color-success);
  color: var(--color-on-success);
}

form {
  width: 100%;
}

form,
.flex-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 620px;
}

.flex-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 200px;
  margin: 10px;
}
input.flex-item {
  width: 0;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-tertiary);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-tertiary-high);
}

::-webkit-scrollbar-track {
  background-color: var(--color-background);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

a, .link {
  display: inline-block;
  cursor: pointer;
  text-decoration: var(--typo-link-decoration);
  color: var(--color-on-background-high);
  font-size: inherit;
}
a:focus, .link:focus {
  outline: var(--outline-focus);
  text-decoration: var(--typo-focus-decoration);
}
a:hover, .link:hover {
  text-decoration: var(--typo-hover-decoration);
}

small {
  font-size: var(--typo-small-size);
  line-height: var(--typo-small-height);
}

h1 {
  display: inline-block;
  position: relative;
  padding: 0 10px;
  margin: 0 30px;
  text-shadow: 0 1px 2px rgb(42, 60, 80);
  border-bottom: 0.08em solid var(--color-on-primary-high);
  box-shadow: 0 1px 0 rgb(42, 60, 80);
  font-size: var(--typo-big-size);
  line-height: var(--typo-big-height);
  font-weight: inherit;
  text-transform: uppercase;
}
h1:after, h1:before {
  content: "";
  position: absolute;
  display: block;
  height: 20px;
  bottom: -0.08em;
}
h1:after {
  left: -14px;
  transform: skew(45deg);
  border-left: 10px solid var(--color-tertiary);
  background-color: white;
  box-shadow: -1px 1px 2px rgb(42, 60, 80), -10px 0 25px 2px rgba(90, 160, 195, 0.35), 0 0 10px rgba(90, 160, 195, 0.35);
}
h1:before {
  right: -14px;
  transform: skew(-45deg);
  border-right: 10px solid var(--color-tertiary);
  background-color: white;
  box-shadow: 1px 1px 2px rgb(42, 60, 80), 10px 0 25px 2px rgba(90, 160, 195, 0.35), 0 0 10px rgba(90, 160, 195, 0.35);
}

h2, h3, h4 {
  font-weight: inherit;
  text-align: left;
  margin: 10px 0;
}

h2 {
  font-size: var(--typo-medium-size);
  line-height: var(--typo-medium-height);
}

.button {
  font-weight: inherit;
  border: var(--border-low);
  background-color: var(--color-primary-low);
  color: var(--color-on-primary);
  border-radius: var(--border-radius);
  padding: var(--spacing-1-2);
  text-align: left;
  cursor: pointer;
  outline-color: transparent;
  transition: color var(--duration-medium), outline-color var(--duration-medium);
}
.button:focus {
  outline: var(--outline-focus);
}
.button:disabled {
  color: var(--color-on-primary-low);
  cursor: default;
}
.button.seamless {
  border: none;
  background: none;
}

input, select {
  box-sizing: border-box;
  outline-color: transparent;
  transition: outline-color var(--duration-medium);
  border: var(--border-low);
  padding: var(--spacing-1-1-half);
  font-weight: inherit;
}

input {
  background-color: var(--color-secondary-low);
  color: var(--color-on-secondary);
}
input:focus {
  outline: var(--outline-focus);
}
input.ng-invalid.ng-touched {
  outline: var(--outline-error);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--color-secondary-low) inset;
  -webkit-text-fill-color: var(--color-on-secondary-high) !important;
}
input[type=checkbox] {
  appearance: none;
  position: relative;
  cursor: pointer;
  padding: 0;
  height: 25px;
  width: 25px;
  margin: 7px 7px 7px 0;
}
input[type=checkbox]:checked:after {
  content: "";
  background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16" style="font-size: 48px;"><path fill="%23f5f5f5" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>');
  position: absolute;
  top: 5px;
  left: 5px;
  height: 15px;
  width: 15px;
}
input[type=checkbox] + label {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 45px);
}

.checkbox {
  display: flex;
  align-items: center;
}

select {
  background-color: var(--color-primary-low);
  color: var(--color-on-primary);
}

/*
select[type="checkbox"] {
  content: "";
  font: normal normal normal 14px/1 FontAwesome;
  display: inline-block;
  height: 12px;
  width: 12px;
  padding: 8px;
  margin-right: 10px;
  vertical-align:middle;
  cursor:pointer;
  background-color: rgb(0, 5, 10);
  border: 1px solid rgba($color-main-comp, $alpha-soft);
  border-radius: 3px;

  outline-color: transparent;
  transition: outline-color $transition-medium;

  &:checked {
    content: "\f00c";
  }

  &.invalid {
    border: 2px solid rgba(190, 30, 30, $alpha-medium);
  }
}
*/
.surface {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  border: var(--border);
  padding: 20px;
  margin: 20px;
}
.surface img {
  max-width: 100%;
}
.surface img.left,
.surface img.right {
  max-width: 50%;
}
.surface img.left {
  float: left;
  margin-right: 10px;
}
.surface img.right {
  float: right;
  margin-left: 10px;
}
.surface .flex-item {
  flex-grow: 1;
  flex-basis: 200px;
  margin: 10px;
}
.surface .flex-item.full-width {
  flex-basis: 400px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
table tr {
  background-color: var(--color-primary);
}
table tr:nth-child(even) {
  background-color: var(--color-primary-low);
}
table td,
table th {
  border: var(--border-low);
  padding: 0.5em;
}
table tbody th {
  text-align: right;
}

.text-button {
  background: none;
  border: none;
  padding: 0;
}

:root {
  --duration-long: .6s;
  --duration-medium: .4s;
  --duration-short: .2s;
}

:root {
  --color-background: rgb(10, 20, 30);
  --color-on-background: rgb(245, 245, 245);
  --color-on-background-high: rgb(120, 190, 230);
  --color-on-background-low: rgb(89, 95, 105);
  --color-primary: rgba(10, 25, 45, 0.8);
  --color-primary-low: rgba(20, 30, 40, 0.8);
  --color-on-primary: rgb(245, 245, 245);
  --color-on-primary-high: rgb(120, 190, 230);
  --color-on-primary-low: rgb(89, 95, 105);
  --color-secondary: rgb(30, 45, 60);
  --color-secondary-high: #2a3f54;
  --color-secondary-low: rgb(0, 5, 10);
  --color-on-secondary: rgb(245, 245, 245);
  --color-on-secondary-high: rgb(120, 190, 230);
  --color-on-secondary-low: rgb(138, 147, 164);
  --color-tertiary-low: rgba(42, 91, 116, 0.7);
  --color-tertiary: rgba(90, 160, 195, 0.7);
  --color-tertiary-high: rgba(146, 192, 215, 0.7);
  --color-error: rgb(120, 30, 30);
  --color-on-error: rgb(245, 245, 245);
  --color-on-error-high: rgb(120, 190, 230);
  --color-on-error-low: rgb(89, 95, 105);
  --color-success: rgb(36, 88, 23);
  --color-on-success: rgb(245, 245, 245);
  --color-on-success-high: rgb(120, 190, 230);
  --color-on-success-low: rgb(89, 95, 105);
  --color-special-google: rgb(244, 67, 54);
  --color-on-special-google: rgb(245, 245, 245);
  --color-on-special-google-high: rgb(120, 190, 230);
  --color-on-special-google-low: rgb(89, 95, 105);
  --color-special-facebook: rgb($color-facebook-blue);
  --color-on-special-facebook: rgb(245, 245, 245);
  --color-on-special-facebook-high: rgb(120, 190, 230);
  --color-on-special-facebook-low: rgb(89, 95, 105);
}

@font-face {
  font-family: "Electrolize";
  font-style: normal;
  font-weight: 400;
  src: local("Electrolize"), url(electrolize-latin-400.woff2) format("woff2");
  font-display: swap;
}
:root {
  --typo-base-family: Electrolize, sans-serif;
  --typo-base-size: 1.1em;
  --typo-base-height: 1.3em;
  --typo-small-size: .8em;
  --typo-small-height: 1.3em;
  --typo-normal-size: 1em;
  --typo-normal-height: 1.3em;
  --typo-medium-size: 1.3em;
  --typo-medium-height: 1.3em;
  --typo-big-size: 1.6em;
  --typo-big-height: 1.3em;
  --typo-link-decoration: none;
  --typo-focus-decoration: none;
  --typo-hover-decoration: underline;
}

:root {
  --outline-focus: .2em solid var(--color-tertiary);
  --outline-error: .2em solid var(--color-error);
  --spacing: 0.55em;
  --spacing-1-2: 0.55em 1.1em;
  --spacing-1-1-half: 0.55em .7em;
  --border: .06em solid var(--color-tertiary);
  --border-low: .06em solid var(--color-tertiary-low);
  --border-radius: .3em;
}