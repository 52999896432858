html, body {
  height: 100%;
}

body {
  font-family: var(--typo-base-family);
  font-size: var(--typo-base-size);
  line-height: var(--typo-base-height);
  font-weight: normal;

  background-color: var(--color-background);
  color:  var(--color-on-background);
  // margin: 0;

  overflow-y: scroll;

  & > picture > img {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    z-index: -10;
  }
}


main > *,
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message-group {
  font-size: var(--typo-small-size);
  line-height: var(--typo-small-height);

  width: 100%;
  margin: 10px;
  overflow: hidden;

  div {
    padding: 10px;
    &.error {
      background-color: var(--color-error);
      color: var(--color-on-error);
    }
    &.success {
      background-color: var(--color-success);
      color: var(--color-on-success);
    }
  }
}

form {
  width: 100%;
}

form,
.flex-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 620px;
}

.flex-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 200px;
  margin: 10px;

  @at-root input#{&} {
    width: 0; // This resets the min-width of input elements
  }
}
