$spacing: .55em;

:root {
  --outline-focus: .2em solid var(--color-tertiary);
  --outline-error: .2em solid var(--color-error);

  --spacing: #{$spacing};

  --spacing-1-2: #{$spacing} #{$spacing*2};
  --spacing-1-1-half: #{$spacing} .7em;

  --border: .06em solid var(--color-tertiary);
  --border-low: .06em solid var(--color-tertiary-low);
  --border-radius: .3em;
}
