$alpha-hard: .8;
$alpha-medium: .7;
$alpha-soft: .2;
$alpha-super-soft: .1;

$color-black: rgb(0, 5, 10);
$color-black-blue: rgb(10, 20, 30); // Also as <meta name="theme-color" content="">

$color-dark-blue-translucent: rgba(10, 25, 45, $alpha-hard);
$color-dark-blue-translucent-low: rgba(20, 30, 40, $alpha-hard);

$color-dark-blue: rgba(30, 45, 60);

$color-light-blue-translucent: rgba(90, 160, 195, $alpha-medium);
$color-light-blue: rgb(120, 190, 230);

$color-red: rgb(120, 30, 30);
$color-green: rgb(36, 88, 23);
$color-white: rgb(245, 245, 245);
$color-gray: rgb(89, 95, 105);
$color-light-gray: rgb(138, 147, 164);

$color-google-red: rgb(244, 67, 54);
$color-facebook-blue: rgb(59,89,152);

:root {
  --color-background: #{$color-black-blue};
  --color-on-background: #{$color-white};
  --color-on-background-high: #{$color-light-blue};
  --color-on-background-low: #{$color-gray};

  --color-primary: #{$color-dark-blue-translucent};
  --color-primary-low: #{$color-dark-blue-translucent-low};
  --color-on-primary: #{$color-white};
  --color-on-primary-high: #{$color-light-blue};
  --color-on-primary-low: #{$color-gray};

  --color-secondary: #{$color-dark-blue};
  --color-secondary-high: #{lighten($color-dark-blue, 7)};
  --color-secondary-low: #{$color-black};
  --color-on-secondary: #{$color-white};
  --color-on-secondary-high: #{$color-light-blue};
  --color-on-secondary-low: #{$color-light-gray};

  --color-tertiary-low: #{darken($color-light-blue-translucent, 25)};
  --color-tertiary: #{$color-light-blue-translucent};
  --color-tertiary-high: #{lighten($color-light-blue-translucent, 15)};
  //--color-on-tertiary: #{$color-black-blue};
  //--color-on-tertiary-high: #{$color-light-blue};
  //--color-on-tertiary-low: #{$color-gray};

  --color-error: #{$color-red};
  --color-on-error: #{$color-white};
  --color-on-error-high: #{$color-light-blue};
  --color-on-error-low: #{$color-gray};

  --color-success: #{$color-green};
  --color-on-success: #{$color-white};
  --color-on-success-high: #{$color-light-blue};
  --color-on-success-low: #{$color-gray};

  --color-special-google: #{$color-google-red};
  --color-on-special-google: #{$color-white};
  --color-on-special-google-high: #{$color-light-blue};
  --color-on-special-google-low: #{$color-gray};

  --color-special-facebook: rgb($color-facebook-blue);
  --color-on-special-facebook: #{$color-white};
  --color-on-special-facebook-high: #{$color-light-blue};
  --color-on-special-facebook-low: #{$color-gray};
}
