::-webkit-scrollbar-button{
  display: none;
}
::-webkit-scrollbar-thumb{
  background-color: var(--color-tertiary);
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover{
  background-color: var(--color-tertiary-high);
}
::-webkit-scrollbar-track{
  background-color: var(--color-background);
}
::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
