table {
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    background-color: var(--color-primary);
    &:nth-child(even) {
      background-color: var(--color-primary-low);
    }
  }

  td,
  th {
    border: var(--border-low);
    padding: .5em;
  }

  tbody {
    th {
      text-align: right;
    }
  }
}
