@use "../../fonts/electrolize";

:root {
  --typo-base-family: Electrolize, sans-serif;

  --typo-base-size: 1.1em;
  --typo-base-height: 1.3em;

  --typo-small-size: .8em;
  --typo-small-height: 1.3em;

  --typo-normal-size: 1em;
  --typo-normal-height: 1.3em;

  --typo-medium-size: 1.3em;
  --typo-medium-height: 1.3em;

  --typo-big-size: 1.6em;
  --typo-big-height: 1.3em;

  --typo-link-decoration: none;

  --typo-focus-decoration: none;
  --typo-hover-decoration: underline;
}
