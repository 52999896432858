.button {
  font-weight: inherit;

  border: var(--border-low);
  background-color: var(--color-primary-low);
  color: var(--color-on-primary);

  border-radius: var(--border-radius);

  padding: var(--spacing-1-2);
  text-align: left;
  cursor: pointer;

  outline-color: transparent;
  transition: color var(--duration-medium), outline-color var(--duration-medium);

  &:focus {
    outline: var(--outline-focus);
  }
  &:disabled {
    color: var(--color-on-primary-low);
    cursor: default;
  }
  &.seamless {
    border: none;
    background: none;
  }
}

input, select {
  box-sizing: border-box;
  outline-color: transparent;
  transition: outline-color var(--duration-medium);

  border: var(--border-low);
  padding: var(--spacing-1-1-half);
  font-weight: inherit;

  //color: inherit;
  //font-size: 1rem;
  //font-family: Roboto, sans-serif;
}

input {
  background-color: var(--color-secondary-low);
  color: var(--color-on-secondary);

  &:focus {
    outline: var(--outline-focus);
  }
  &.ng-invalid.ng-touched {
    outline: var(--outline-error);
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px var(--color-secondary-low) inset;
    -webkit-text-fill-color: var(--color-on-secondary-high) !important;
  }

  &[type="checkbox"] {
    appearance: none;

    position: relative;
    cursor: pointer;
    padding: 0;
    height: 25px;
    width: 25px;
    margin: 7px 7px 7px 0;

    &:checked:after {
      content: "";
      background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16" style="font-size: 48px;"><path fill="%23f5f5f5" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>');

      position: absolute;
      top: 5px;
      left: 5px;
      height: 15px;
      width: 15px;
    }

    & + label {
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 45px);
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
}

select {
  background-color: var(--color-primary-low);
  color: var(--color-on-primary);
}

/*
select[type="checkbox"] {
  content: "";
  font: normal normal normal 14px/1 FontAwesome;
  display: inline-block;
  height: 12px;
  width: 12px;
  padding: 8px;
  margin-right: 10px;
  vertical-align:middle;
  cursor:pointer;
  background-color: rgb(0, 5, 10);
  border: 1px solid rgba($color-main-comp, $alpha-soft);
  border-radius: 3px;

  outline-color: transparent;
  transition: outline-color $transition-medium;

  &:checked {
    content: "\f00c";
  }

  &.invalid {
    border: 2px solid rgba(190, 30, 30, $alpha-medium);
  }
}
*/
