.surface {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  border: var(--border);

  padding: 20px; // TODO
  margin: 20px; // TODO

  img {
    max-width: 100%;
  }

  img.left,
  img.right {
    max-width: 50%;
  }
  img.left {
    float: left;
    margin-right: 10px;
  }
  img.right {
    float: right;
    margin-left: 10px;
  }

  .flex-item {
    flex-grow: 1;
    flex-basis: 200px;
    margin: 10px;

    &.full-width {
      flex-basis: 400px;
    }
  }
}
