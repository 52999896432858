@font-face {
  font-family: 'Electrolize';
  font-style: normal;
  font-weight: 400;
  src:
    local('Electrolize'),
    url(electrolize-latin-400.woff2) format('woff2')
  ;
  font-display: swap;
}
