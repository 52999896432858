a, .link {
  display: inline-block;
  cursor: pointer;
  text-decoration: var(--typo-link-decoration);
  color: var(--color-on-background-high);
  font-size: inherit;
  &:focus {
    outline: var(--outline-focus);
    text-decoration: var(--typo-focus-decoration);
  }
  &:hover {
    text-decoration: var(--typo-hover-decoration);
  }
}

small {
  font-size: var(--typo-small-size);
  line-height: var(--typo-small-height);
}

h1 {
  display: inline-block;
  position: relative;

  padding: 0 10px; // TODO
  margin: 0 30px; // TODO

  $dark-shadow-color: rgb(42, 60, 80);

  text-shadow: 0 1px 2px $dark-shadow-color; // TODO
  border-bottom: .08em solid var(--color-on-primary-high);

  box-shadow:
    0 1px 0 $dark-shadow-color;

  font-size: var(--typo-big-size);
  line-height: var(--typo-big-height);
  font-weight: inherit;
  text-transform: uppercase;

  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 20px; // TODO
    bottom: -.08em;
  }

  $shadow-color: rgba(90, 160, 195, 0.35);

  &:after {
    left: -14px; // TODO
    transform: skew(45deg);
    border-left: 10px solid var(--color-tertiary);
    background-color: white;
    box-shadow:
      -1px 1px 2px $dark-shadow-color, // Left bottom Stroke
      -10px 0 25px 2px $shadow-color, // Left Blur
      0 0 10px $shadow-color // Middle Blur
    ;
    // TODO
  }
  &:before {
    right: -14px; // TODO
    transform: skew(-45deg);
    border-right: 10px solid var(--color-tertiary);
    background-color: white;
    box-shadow:
      1px 1px 2px $dark-shadow-color, // Right bottom Stroke
      10px 0 25px 2px $shadow-color, // Right Blur
      0 0 10px $shadow-color // Middle Blur
    ;
    // TODO
  }
}

h2, h3, h4 {
  font-weight: inherit;
  text-align: left;
  margin: 10px 0; // TODO
}

h2 {
  font-size: var(--typo-medium-size);
  line-height: var(--typo-medium-height);
}
